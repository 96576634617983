import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import Curso from "../components/curso"
import Tree from "../images/cursos/Tree.webp"
import IxtlanPhoto from "../Assets/ixtlan.webp"

const Ixtlan = () => {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Curso 
                title='Viaje a Ixtlán'
                phrase='Fortalecernos y responsabilizarnos para vivir con plenitud.'
                time='8 Horas'
                class='6 Sesiones'
                students='3 - 6 Estudiantes'
                firstImg={Tree}
                secondImg={IxtlanPhoto}
                description='En este curso analizaremos a profundidad los primeros 11 capítulos del libro Viaje a Ixtlán de C. Castaneda. Este libro nos presenta las ideas que rigen la vida del chamán Don Juan; ideas que nos muestran la posibilidad de vivir con mayor libertas, responsabilidad y profundidad. El enfoque del curso es completamente personal y psicológico, usando las ideas de Don Juan como herramientas de introspección y transformación.'
                list={['Introducción y Las reafirmaciones del mundo que nos rodea.', 'Borrar la historia personal y Perder la importancia.', 'La muerte como consejera y Hacerse responsable.', 'Volverse cazador y Ser inaccesible.', 'Romper las rutinas de la vida y La última batalla sobre la tierra.', 'El ánimo de un guerrero y No hacer.']}
                duracion='6 sesiones de entre 80 y 90 min.'
                cupo='mínimo 3 personas, máximo 6 personas.'
                precio='$1,500 MXN.'
                top='10%'
                imgWidth='20%'
                right='5%'
                topMob='15%'
                rightMob='1%'
                imgWidthMob='28%'
            />
        </Layout>
    ) : null
}

export default Ixtlan